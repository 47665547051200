<template lang="pug">
.container
  b-card.border-0
    .d-flex.justify-content-center
      h1 {{ profile.name }}
    full-calendar(ref="calendar" :options="calendarOptions")
      template(v-slot:eventContent="arg")
        .d-flex.justify-content-center {{ arg.event.title }}
    .d-flex.justify-content-end.align-items-center.mt-3
      div
        b-button.mr-1(
          variant="primary"
          @click="showSaveConfirm"
          :disabled="(events.added.length + events.deleted.length) <= 0"
        ) Submit
</template>

<script>
/* eslint-disable no-unused-vars */

import FullCalendar from '@fullcalendar/vue';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import TimeGridPlugin from '@fullcalendar/timegrid';

import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

import { uniqueId } from 'lodash';
import moment from 'moment';

import {
  getColorAndTitle,
  getRestTime,
  isTimeRangeInRestTime,
  isPastOrCurrent,
  STATUS,
} from '@/utils/schedule';
import api from '@lioshutan/api-package';
import storage from '@/utils/localStorage';

const DISPLAY = 'Display';
const DELETE = 'Delete';
const ADD = 'Add';

export default {

  components: {
    FullCalendar,
  },

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      modal: {
        repitition: false,
      },
      regularSchedule: {
        days: 1,
        weeks: 1,
        show: false,
      },
      calendarApi: null,
      start: null,
      end: null,
      token: '',
      now: '',
      // All of Moment objects should be formatted to this format.
      timeFormat: 'YYYY-MM-DD HH:mm',
      // The forbidden area displayed in the calendar.
      forbid: {
        title: 'NO CLASS',
        start: '00:30',
        end: '09:00',
        rendering: 'background',
        overlap: false,
        color: '#ff9f89',
      },
      events: {
        added: [],
        deleted: [],
      },

      profile: {
        name: '',
      },
      calendarOptions: {
        // initialDate: new Date(2021, 11, 26),
        // visibleRange: {
        //   start: new Date(2021, 12, 26),
        //   end: new Date(2022, 1, 1),
        // },
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          TimeGridPlugin,
        ],
        allDaySlot: false,
        headerToolbar: false,
        // headerToolbar: {
        //   left: 'prev,next',
        //   center: 'title',
        //   right: 'dayGridMonth,timeGridWeek,timeGridDay,today',
        // },
        selectOverlap: false,
        initialView: 'timeGridWeek',
        // locale: 'tw',
        selectable: true,
        select: this.handleDateSelect,
        eventAdd: this.eventAdd,

        events: this.handleEvents,
        // https://fullcalendar.io/docs/eventClick < eventClick文件
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventChange: this.eventChange,
        eventRemove: this.eventRemove,
        /* you can update a remote database when these fire:
        */
      },
      currentEvents: [],
    };
  },
  async created() {
    this.token = this.$store.state.auth.token;
  },
  mounted() {
    this.calendarApi = this.$refs['calendar']['getApi']();
    // if (this.$route.query['date']) {
    //   this.$refs['calendar']['getApi']()['gotoDate'](this.$route.query['date']);
    // }
  },
  methods: {
    refreshSchedule() {
      // TODO: 這邊要做重新刷新行事曆的動作
      location.reload();
    },
    async updateFixedSchedule(add, del) {
      try {
        const tag = this.startLoading();
        const response = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .consultant(this.token)
          .setConsultantsFixedSchedule(add, del, 1);
        this.refreshSchedule();
        this.endLoading(tag);
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @param {array} events
     */
    getAddedParams(events) {
      const params = [];
      for (let i = 0; i < events.length; i++) {
        const target = events[i];
        if (target.id) {
          const start = moment(target.start);
          const end = moment(target.end);
          while (!start.isSame(end)) {
            let timestamp = start.format('YYYYMMDD');
            let timeslot = (moment(start.format('YYYY-MM-DD HH:mm')).subtract(9, 'hour').get('hour')) * 2;

            timeslot = (start.format('mm') === '00') ? timeslot : timeslot + 1;
            timeslot++;

            timestamp = (timeslot === 31) ? moment(timestamp, 'YYYYMMDD').subtract(1, 'day').format('YYYYMMDD') : timestamp;
            timestamp += ((timeslot > 9) ? timeslot : '0' + timeslot);
            params.push({
              timestamp: parseInt(timestamp, 10),
              timeslot: timeslot,
            });
            start.add(30, 'minutes');
          }
        }
      }
      return params;
    },
    async showSaveConfirm() {
      try {
        await swal.fire({
          title: 'Save Schedule Confirm',
          text: 'Are you sure you want to submit your schedule?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Submit',
        });

        const add = this.getAddedParams(this.events.added);

        await this.updateFixedSchedule(add, this.events.deleted);
      } catch (error) {
        console.log('error', error);
      }
    },
    /**
     * 抓取所有行程
     */
    async handleEvents({ start, end }, success, fail) {
      this.start = start;
      this.end = end;

      try {
        const response = await this.getConsultantFixeSchedule({
          start,
          end,
        });
        success(response);
      } catch (error) {
        console.log(error);
        fail(error);
      }
    },

    /**
     * 取得顧問排程
     *
     * @returns {Promise<Array>} 顧問排成
     */
    async getConsultantFixeSchedule({ start, end }) {
      const tag = this.startLoading();

      const sunday = moment(start).format('YYYYMMDD');
      const saturday = moment(end).format('YYYYMMDD');
      this.id = this.$route.params['id'];

      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .consultant(this.token)
        .getConsultantFixeSchedule();

      // hbConsultantId: 1051
      // id: 906553
      // isPb: false
      // status: 1
      // timeslot: 13
      // timestamp: 2021122613
      // type: "1"

      const result = response.data.map(({
        id,
        status,
        timeslot,
        timestamp,
        type,
      }) => {
        const todayMoment = moment();
        const firstDay = todayMoment.add(-todayMoment.weekday(), 'days');
        const startFromServer = moment(
          `${timestamp.toString().substring(0, 8)}0830`,
          'YYYY-MM-DD HH:mm'
        )
          .add(timeslot * 30, 'minute');
        const start = moment(`${firstDay.add(startFromServer.weekday(), 'days').format('YYYY-MM-DD')} ${startFromServer.format('HH:mm:ss')}`);
        const end = moment(start.format('YYYY-MM-DD HH:mm')).add(30, 'minute');

        const result = {
          id,
          start: start.toISOString(),
          end: end.toISOString(),
          status,
          type: +type,
        };

        const colorAndTitle = getColorAndTitle(status, +type);

        return {
          backgroundColor: colorAndTitle.color,
          borderColor: colorAndTitle.color,
          title: colorAndTitle.title,
          ...result,
        };
      });

      result.push(...getRestTime(start, end).map((time) => ({
        ...time,
        display: 'background',
        color: '#ff9f89',
      })));

      this.endLoading(tag);

      return result;
    },

    /**
     * 針對未排定的部分動作
     */
    handleDateSelect({ start, end, startStr, endStr, view: { calendar }}) {
      if (isTimeRangeInRestTime(start, end)) {
        alert('您無法在休息時間排課！');
        return;
      }
      const title = '等待送出';
      const calendarApi = calendar;

      calendarApi.unselect(); // clear date selection
      const id = uniqueId('unsaved');

      calendarApi.addEvent({
        id,
        title,
        start: startStr,
        end: endStr,
        // allDay: selectInfo.allDay,
      });
    },

    /**
     * 針對Event動作
     */
    async handleEventClick({
      el,
      event,
      // {
      //   id,
      //   groupId,
      //   title,
      //   display,
      //   extendedProps: {
      //     status,
      //     type,
      //     cusInfo,
      //   },
      //   start,
      //   end,
      //   remove,
      // },
      jsEvent,
      view,
    }) {
      // https://fullcalendar.io/docs/event-object < Event物件文件
      // console.log([event.id, event, event.backgroundColor, event.borderColor]);
      // event.backgroundColor = '#111111';
      // console.log([event.id, event.groupId, event.title, event.start, event.end, event.extendedProps]);
      if (isTimeRangeInRestTime(event.start, event.end)) {
        return;
      }

      event.remove();

      if (!event.id.includes('unsaved')) {
        this.events.deleted.push(event.id);
        return;
      }
    },
    /**
     * https://fullcalendar.io/docs/eventAdd
     */
    eventAdd({ event, relatedEvents, revert }) {
      this.events.added.push({
        id: event.id,
        title: event.title,
        start: event.startStr,
        end: event.endStr,
      });
    },
    /**
     * https://fullcalendar.io/docs/eventRemove
     */
    eventRemove({ event, relatedEvents, revert }) {
      this.events.added = this.events.added.filter((add) => add.id !== event.id);
    },

    eventChange(data) {
      // TODO: 還不知道能拿來做什麼
    },

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
  },
};
</script>
